/* eslint-disable quotes */
/* eslint-disable semi */
const ranges = [
  { divider: 1e18, suffix: "E" },
  { divider: 1e15, suffix: "P" },
  { divider: 1e12, suffix: "T" },
  { divider: 1e9, suffix: "G" },
  { divider: 1e6, suffix: "M" },
  { divider: 1e3, suffix: "k" },
];

export const formatNumber = (n) => {
  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      return (n / ranges[i].divider).toString() + ranges[i].suffix;
    }
  }

  return n;
};

export const capitalize = (text) => {
  return `${text.charAt(0).toUpperCase()}${text
    .slice(1)
    .replace(/[\W_-]/g, " ")}`;
};

export const removeSpecialChars = (value) => value.replace(/[^\w\s]|_/gi, "");

export const safelyGetNumber = (value, defaultValue = 0) =>
  isNaN(value) ? defaultValue : value;

export const isUsernameNumeric = (val) => {
  if (/^\d+$/.test(val)) {
    return "+";
  }

  return "";
};

export const formatBytes = (bytes) => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;
  const terabyte = gigabyte * 1024;

  if (bytes < kilobyte) {
    return bytes.toFixed(2) + " Bytes";
  } else if (bytes < megabyte) {
    return (bytes / kilobyte).toFixed(2) + " KB";
  } else if (bytes < gigabyte) {
    return (bytes / megabyte).toFixed(2) + " MB";
  } else if (bytes < terabyte) {
    return (bytes / gigabyte).toFixed(2) + " GB";
  } else {
    return (bytes / terabyte).toFixed(2) + " TB";
  }
};

export const formatToGb = (num) => {
  const bytes = 1024 * 1024 * 1024
  return num / bytes
}

export const getCurrencyFormatted = (unit) => {
  return (unit / 100).toFixed(2)
}
