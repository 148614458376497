<template>
  <v-row
    class="pa-0 ma-0 mb-3 app-card"
    @click="viewUser(user.user_id)"
  >
    <v-col class="" cols="1">
      <v-icon v-if="user.verified" color="green">mdi-check</v-icon>
    </v-col>
    <v-col class="d-flex ml-n12 flex-column" cols="5">
      <div class="d-flex align-center">
        <v-avatar class="mr-2" size="24">
          <v-img
            aspect-ratio="1"
            max-height="24"
            max-width="24"
            cover
            :lazy-src="require(`@/assets/avatars/user.svg`)"
            :src="userAvatarImage"
          />
        </v-avatar>
        <div class="d-flex align-center name-holder" style="width: 100%;">
          <s-text
            weight="medium"
            color="grayTextAlt"
            style="cursor: pointer;"
          >
            {{ username }}
          </s-text>
        </div>
      </div>
    </v-col>
    <v-col class="d-flex flex-column" cols="3">
      <div>
        {{ user.handle || 'n/a' }}
      </div>
    </v-col>
    <v-col class="d-flex flex-column" cols="3">
      <div>
        {{ user.created_at }}
      </div>
    </v-col>
    <v-col class="action_group d-flex align-center">
      <s-icon name="arrow-circle-right" />
    </v-col>
  </v-row>
</template>

<script>
import { isUsernameNumeric } from '@/utils/fmt'
export default {
  name: 'UserCard',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userAvatarImage () {
      return `${process.env.VUE_APP_API_DOMAIN}/v1/users/${this.user.user_id}/picture`
    },
    username () {
      if (this.user.bio.name !== undefined && this.user.bio.name !== '') return this.user.bio.name
      if (isUsernameNumeric(this.user.username) === '+') return this.user.username
      return this.user.username
    },
  },
  methods: {
    isUsernameNumeric,
    viewUser () {
      this.$router.push({ name: 'appDetails', params: { id: this.user.user_id } })
    },
  },
}
</script>
